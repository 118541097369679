import React from "react";
import { Link } from "react-scroll";
import { StaticImage } from "gatsby-plugin-image";
import { ErrorBoundary } from "./error";

const Footer = (props) => {
  let { show } = props;

  if (!show) {
    return null;
  }

  return (
    <ErrorBoundary>
      <div className="text-center pb-3 mt-5 pt-5" style={{ fontFamily: "Space Grotesk" }}>
        Studio Bara · København V · DK-1708 · bara@studiobara.com ·{" "}
        <a className="text-dark" href="https://instagram.com/studiobara_" target="_blank" rel="noreferrer">
          Instagram
        </a>
      </div>
      <div className={`m-2 mb-5 m-sm-4 mb-sm-5 float-right text-right fixed-bottom`} style={{paddingRight: "15px"}}>
        <Link to="top" smooth duration={1000} style={{ cursor: "pointer" }} className="p-3 mb-3">
          <StaticImage
            src="../../content/website_arrow_square.png"
            alt="up-arrow"
            width={35}
            imgStyle={{ transform: "rotate(270deg)" }}
          />
        </Link>
      </div>
    </ErrorBoundary>
  );
};

export default React.memo(Footer);
