import React from "react";
import baraStudio from "../images/bara_studio.png";
import baraStudioBlack from "../images/bara_studio_black.png";
import Link from "gatsby-link";
import { ErrorBoundary } from "./error";

const Header = (props) => {
  let { blackBackground } = props;

  const win = typeof window !== "undefined" ? window : null;

  let currentUrl = win?.location.pathname;

  let logo = blackBackground ? baraStudio : baraStudioBlack;

  return (
    <ErrorBoundary>
      <div className={`row m-2 pt-4 m-sm-4 pt-sm-3`} style={{ zIndex: 500 }}>
        <div className="col-6">
          <Link to="/">
            <img src={logo} alt="Studio Bara corner logo" width="100rem" />
          </Link>
        </div>
        <div className="col-6">
          <div className="row">
            <HeaderLink
              name="Projects"
              linkTo="/projects/"
              currentUrl={currentUrl}
              textColor={blackBackground ? "text-white" : "text-black"}
            />
            <HeaderLink
              name="Shop"
              linkTo="/shop/"
              currentUrl={currentUrl}
              textColor={blackBackground ? "text-white" : "text-black"}
            />
            <HeaderLink
              name="About"
              linkTo="/about/"
              currentUrl={currentUrl}
              textColor={blackBackground ? "text-white" : "text-black"}
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default React.memo(Header);

const HeaderLink = (props) => {
  let { linkTo, name, currentUrl, textColor } = props;

  return (
    <div className="col-12">
      <Link to={linkTo} className="float-right link">
        <h4 className={`mb-1 ${textColor}`} style={{ textDecoration: currentUrl === linkTo ? "underline" : "none" }}>
          {name}
        </h4>
      </Link>
    </div>
  );
};
