import React from "react";
import SEO from "./seo";
import Header from "./header";
import Footer from "./footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/main.css";
import "../styles/fonts.css";
import { ErrorBoundary } from "./error";

const PageLayout = (props) => {
  let { title, children, footer = true, onShop, blackBackground } = props;

  return (
    <ErrorBoundary>
      <div id="top" className={`container-fluid pt-3 ${blackBackground ? "bg-black" : ""}`}>
        <SEO title={title} />
        <div className="min-vh-100" style={{ fontFamily: "Space Grotesk" }}>
          <Header onShop={onShop} blackBackground={blackBackground} />
          {children}
          <br />
        </div>
        <Footer show={footer} />
      </div>
    </ErrorBoundary>
  );
};

export default PageLayout;
