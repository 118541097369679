import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../images/favicon.ico';

const SEO = ({ title, description, image }) => {
  const { pathname } = useLocation();

  const { site } = useStaticQuery(query);

  const { defaultTitle, defaultDescription, siteUrl, defaultImage } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={`${seo.title} | Studio Bara`}>
      <meta property="og:title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta name="author" content="Bara Strelcová" />
      <meta name="image" content={seo.image} />
      <meta property="og:image" content={seo.image} />
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle
        defaultDescription
        siteUrl
        defaultImage
      }
    }
  }
`;
